import React from 'react'

import ContactForm from '../components/contactform'

import Layout from "../components/layout"
import SEO from "../components/seo"


export default function Contact() {

    return(

    <Layout>
    <SEO title="Contact" />
        <div className="contact-body-wrapper">
        
            <ContactForm />
            
        </div>
    </Layout>
    
    )
}
