import React from 'react'

const ContactForm = () => {

    return(
        <div className="contactform-wrapper">
            <h2 className="contact-title kill-margin">Contact Us</h2>
            <form method="POST" action="https://formspree.io/hello@foundstages.org" class="contact-form-body">
                    <div className="inputs-wrapper">
                        <input className="contact-input half-to-full" type="email" name="email" placeholder="Your email" />
                        <input className="contact-input half-to-full" type="text" name="name" placeholder="Your name" />
                    </div>

                <input className="contact-input subject-line" type="text" name="subject" placeholder="Subject" />
                

                <textarea name="message" placeholder="Message..." className="contact-input message-area"></textarea>

                <button type="submit" className="button--fullWidth blackButton--border">Send Message</button>
            </form>
        </div>
    )

}

export default ContactForm